.CustomDayPickerRange .DayPicker {
  color: #2a2859;
  width: 100%;
  font-family: 'Oslo Sans';
}

.CustomDayPickerRange .DayPicker-Weekday {
  color: #2a2859;
}

.CustomDayPickerRange .DayPicker-Day {
  width: 40px;
  height: 40px;
  border-radius: 0 !important;
}

.CustomDayPickerRange .DayPicker-Day:hover {
  background-color: transparent !important;
}

.CustomDayPickerRange
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
  color: #0a0a0a;
  background-color: #c6cade;
}

.CustomDayPickerRange
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):hover {
  background-color: #c6cade !important;
}

.CustomDayPickerRange .DayPicker-Day--start {
  color: #fff !important;
  background-color: #4c5392 !important;
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.CustomDayPickerRange .DayPicker-Day--start:hover {
  background-color: #4c5392 !important;
}

.CustomDayPickerRange .DayPicker-Day--end {
  color: #fff !important;
  background-color: #4c5392 !important;
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.CustomDayPickerRange .DayPicker-Day--end:hover {
  background-color: #4c5392 !important;
}

.CustomDayPickerRange .DayPicker-Month {
  margin: 0;
}

.CustomDayPickerRange .DayPicker-Day--disabled {
  color: #a2a8c8;
}

.CustomDayPickerRange .DayPicker-NavBar {
  display: flex;
  padding: 0 15px 0 10px;
}

.CustomDayPickerRange .DayPicker-Day--today {
  color: inherit;
  font-weight: inherit;
}

.CustomDayPickerRange .DayPicker-wrapper {
  padding-bottom: 0;
}

.CustomDayPickerRange .DayPicker-WeekNumber {
  height: 40px;
  display: flex;
  align-items: center;
}
