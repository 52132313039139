.CalendarCourse .DayPicker {
  color: #2a2859;
  width: 100%;
  font-family: 'Oslo Sans';
}

.CalendarCourse .DayPicker-Weekday {
  color: #2a2859;
}

.CalendarCourse .DayPicker-Day {
  width: 40px;
  height: 40px;
}

.CalendarCourse .DayPicker-Day:hover {
  background-color: transparent !important;
}

.CalendarCourse
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  color: #fff;
  background-color: #4c5392;
}

.CalendarCourse
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #4c5392 !important;
}

.CalendarCourse .DayPicker-Month {
  margin: 0;
}

.CalendarCourse .DayPicker-wrapper {
  padding-bottom: 0;
  margin-top: -10px;
}

.CalendarCourse .DayPicker-Day--disabled {
  color: #a2a8c8;
}

.CalendarCourse .DayPicker-NavBar {
  display: flex;
  padding: 0 15px 0 10px;
}

.CalendarCourse .DayPicker-Day--today {
  color: inherit;
  font-weight: inherit;
}
