@font-face {
    font-family: 'Oslo Sans';
    src: url('./OsloSans-Bold.woff');
    font-weight: bold;
}

@font-face {
    font-family: 'Oslo Sans';
    src: url('./OsloSans-BoldItalic.woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Oslo Sans';
    src: url('./OsloSans-Light.woff');
    font-weight: 300;
}

@font-face {
    font-family: 'Oslo Sans';
    src: url('./OsloSans-LightItalic.woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Oslo Sans';
    src: url('./OsloSans-Medium.woff');
    font-weight: 500;
}

@font-face {
    font-family: 'Oslo Sans';
    src: url('./OsloSans-MediumItalic.woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Oslo Sans';
    src: url('./OsloSans-RegularItalic.woff');
    font-style: italic;
}

@font-face {
    font-family: 'Oslo Sans';
    src: url('./OsloSans-Regular.woff');
}